import ApplicationController from './application_controller.js'

export default class extends ApplicationController {
  static targets = ["talent", "assignmentId", "filterId", "shortlistedTalent", "skillSearchField"]

  // Shortlist functionality (add/remove talent from shortlist)
  onShortlist(e){
    e.preventDefault()
    let talent = e.target.getAttribute("data-talent-id")
    let assignmentId = this.assignmentIdTarget.getAttribute("data-assignment-id")
    let filterId = this.filterIdTarget.getAttribute("data-filter-id")
    if (assignmentId && talent){
      this.stimulate('Shortlist#shortlist', talent, assignmentId, filterId)
    }
  }

  onShortlistRemove(e){
    let talent = e.target.getAttribute("data-talent-id")
    let assignmentId = this.assignmentIdTarget.getAttribute("data-assignment-id")
    let filterId = this.filterIdTarget.getAttribute("data-filter-id")
    if (assignmentId && talent){
      this.stimulate('Shortlist#remove_from_shortlist', talent, assignmentId, filterId)
    }
  }

  // Contact events functionality
  showContactDetailsModal(e){
    e.preventDefault()
    let talent = e.target.getAttribute("data-talent-id")
    let assignmentId = this.assignmentIdTarget.getAttribute("data-assignment-id")
    if (assignmentId && talent){
      this.stimulate('Shortlist#show_contact_details_modal', talent, assignmentId)
    }
  }

  // Contact events functionality
  hideContactDetailsModal(e){
    e.preventDefault()
    this.stimulate('Shortlist#hide_contact_details_modal')
  }


  // Search functionality
  onSelectSkill(e){
    let selectedId = e.target.getAttribute('data-skill-id');
    let filterId = this.filterIdTarget.getAttribute("data-filter-id")

    if (selectedId){
      this.skillSearchFieldTarget.value = ""
      this.stimulate('Shortlist#select_skill', selectedId, filterId)
    }
  }

  onDeleteSkill(e){
    e.preventDefault()
    let selectedId = e.target.getAttribute('data-skill-id');
    let filterId = this.filterIdTarget.getAttribute("data-filter-id")

    if (selectedId){
      this.stimulate('Shortlist#delete_skill', selectedId, filterId)
    }
  }

  onSearchSkill(e){
    e.preventDefault()
    let filterId = this.filterIdTarget.getAttribute("data-filter-id")
    let searchTerm = this.skillSearchFieldTarget.value;
    this.stimulate('Shortlist#search_skill', searchTerm, filterId)
  }

}

