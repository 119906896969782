import ApplicationController from './application_controller.js'
export default class extends ApplicationController {
  static targets = ["details"]
  static classes = [ "hidden" ]

  openSkillCard(e){
    this.stimulate('Talent#toggle_skill_card', true)
  }

  closeSkillCard(e){
    this.stimulate('Talent#toggle_skill_card', false)
  }

  showFileUpload(e){
    this.stimulate('Talent#toggle_file_upload', true)
  }

  hideFileUpload(e){
    this.stimulate('Talent#toggle_file_upload', false)
  }

  openContactEventForm(e){
    e.preventDefault()
    let talent = e.target.getAttribute("data-talent-id")
    if ( talent){
      this.stimulate('Talent#show_contact_details_modal', talent)
    }
  }


}

