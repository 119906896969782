import ApplicationController from './application_controller.js'

export default class extends ApplicationController {

  showIntroductionModal(e){
    e.preventDefault()
    let introductionId = e.target.getAttribute("data-introduction-id")
    if (introductionId){
      this.stimulate('Introduction#show_introduction_modal', introductionId)
    }
  }

  showMeetingModal(e){
    e.preventDefault()
    let introductionId = e.target.getAttribute("data-introduction-id")
    if (introductionId){
      this.stimulate('Introduction#show_meeting_modal', introductionId)
    }
  }

  hideModal(e){
    e.preventDefault()
    this.stimulate('Shortlist#hide_contact_details_modal')
  }

  showCustomerForm(e){
    this.stimulate('Introduction#toggle_customer_form', true)
  }

  hideCustomerForm(e){
    this.stimulate('Introduction#toggle_customer_form', false)
  }
}

