import ApplicationController from './application_controller.js'

export default class extends ApplicationController {
  static targets = []

  show(e){
    e.preventDefault()
    this.stimulate('Modal#show')
  }

  hide(e){
    e.preventDefault()
    this.stimulate('Modal#hide')
  }

}

