import ApplicationController from './application_controller.js'
export default class extends ApplicationController {
  static targets = ["details"]
  static classes = [ "hidden" ]

  onClickTalent(e){
    this.detailsTarget.hidden = !this.detailsTarget.hidden;
  }

}

