import ApplicationController from './application_controller.js'

export default class extends ApplicationController {
  static targets = [ "toggleable", "searchField" ]

  onSearch(e){
    e.preventDefault()
    let searchTerm = this.searchFieldTarget.value;
    this.stimulate('SkillPicker#search', searchTerm)
  }

  onSelectSkill(e){
    e.preventDefault()
    let selectedId = e.target.getAttribute('data-skill-id');
    let assignmentId = e.target.getAttribute('data-assignment-id');
    let talentId = e.target.getAttribute('data-talent-id');

    if (selectedId){
      this.searchFieldTarget.value = ""
      this.stimulate('SkillPicker#select', selectedId, assignmentId, talentId)
    }
  }

  onAddSkill(e){
    e.preventDefault()
    let assignmentId = e.target.getAttribute('data-assignment-id');
    let talentId = e.target.getAttribute('data-talent-id');
    let name = this.searchFieldTarget.value

    this.searchFieldTarget.value = ""
    this.stimulate('SkillPicker#add', name, assignmentId, talentId)
  }


  onDeleteSkill(e){
    e.preventDefault()
    let selectedId = e.target.getAttribute('data-skill-id');
    let assignmentId = e.target.getAttribute('data-assignment-id');
    let talentId = e.target.getAttribute('data-talent-id');

    if (selectedId){
      this.stimulate('SkillPicker#delete', selectedId, assignmentId, talentId)
    }
  }
}
