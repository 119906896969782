import ApplicationController from './application_controller.js'
export default class extends ApplicationController {
  static targets = ["input", "noteableId", "noteableType", "sideBar"]

  onSaveNote(e){
    e.preventDefault()
    let note = this.inputTarget.value
    this.inputTarget.value = ""
    let noteableId = this.noteableIdTarget.getAttribute("data-noteable-id")
    let noteableType = this.noteableTypeTarget.getAttribute("data-noteable-type")
    this.stimulate('Note#create_note', note, noteableId, noteableType)
  }

  onToggleVisibility(e){
    if (this.sideBarTarget.hasAttribute("class", "hidden")){
      this.sideBarTarget.removeAttribute("class", "hidden");
    } else {
      this.sideBarTarget.setAttribute("class", "hidden");
    }
  }

}

