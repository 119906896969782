import ApplicationController from './application_controller.js'

export default class extends ApplicationController {
  static targets = ["filterId", "nameSearchField"]

  onSearchName(e){
    e.preventDefault()
    let searchTerm = this.nameSearchFieldTarget.value;
    this.stimulate('CustomerSearch#search_name', searchTerm)
  }
}

