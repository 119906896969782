import ApplicationController from './application_controller.js'

export default class extends ApplicationController {
  static targets = ["filterId", "skillSearchField", "nameSearchField", "locationSearchField"]
  
  // Search functionality
  onSelectSkill(e){
    let selectedId = e.target.getAttribute('data-skill-id');
    let filterId = this.filterIdTarget.getAttribute("data-filter-id")

    if (selectedId){
      this.skillSearchFieldTarget.value = ""
      this.stimulate('TalentSearch#select_skill', selectedId, filterId)
    }
  }

  onDeleteSkill(e){
    e.preventDefault()
    let selectedId = e.target.getAttribute('data-skill-id');
    let filterId = this.filterIdTarget.getAttribute("data-filter-id")

    if (selectedId){
      this.stimulate('TalentSearch#delete_skill', selectedId, filterId)
    }
  }

  onSearchSkill(e){
    e.preventDefault()
    let filterId = this.filterIdTarget.getAttribute("data-filter-id")
    let searchTerm = this.skillSearchFieldTarget.value;
    this.stimulate('TalentSearch#search_skill', searchTerm, filterId)
  }

  onSearchName(e){
    e.preventDefault()
    let filterId = this.filterIdTarget.getAttribute("data-filter-id")
    let searchTerm = this.nameSearchFieldTarget.value;
    this.stimulate('TalentSearch#search_name', searchTerm, filterId)
  }

  onSearchLocation(e){
    e.preventDefault()
    let filterId = this.filterIdTarget.getAttribute("data-filter-id")
    let searchTerm = this.locationSearchFieldTarget.value;
    this.stimulate('TalentSearch#search_location', searchTerm, filterId)
  }
}

